// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-js": () => import("../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-js": () => import("../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-blog-list-template-js": () => import("../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-pages-covid-jsx": () => import("../src/pages/covid.jsx" /* webpackChunkName: "component---src-pages-covid-jsx" */),
  "component---src-pages-food-jsx": () => import("../src/pages/food.jsx" /* webpackChunkName: "component---src-pages-food-jsx" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-registry-jsx": () => import("../src/pages/registry.jsx" /* webpackChunkName: "component---src-pages-registry-jsx" */),
  "component---src-pages-rsvp-jsx": () => import("../src/pages/rsvp.jsx" /* webpackChunkName: "component---src-pages-rsvp-jsx" */),
  "component---src-pages-timeline-jsx": () => import("../src/pages/timeline.jsx" /* webpackChunkName: "component---src-pages-timeline-jsx" */),
  "component---src-pages-venue-jsx": () => import("../src/pages/venue.jsx" /* webpackChunkName: "component---src-pages-venue-jsx" */)
}

